<template>
  <div v-if="loading" class="mx-0">
    <CustomLoader />
  </div>
  <div class="mx-0 bank-single-idea-request" v-else>
    <a href="" @click.prevent="goBack" class="back-to-btn">
      <img src="/media/buying/icons/arrow-left.svg" alt="">
      {{ $t('Back to Ideas') }}
    </a>
    <div class="col-12">
      <BankIdeaItem
        :singleRequest="true"
        :ideaData="idea"
        @handle-toggle-status="toggleIdeaStatus"
      />
    </div>
    <div class="card my-10 pt-7" v-if="currentUser.can(BankUserPermissionTypeEnum.COMMENT_IDEA)">
      <div class="row">
        <div class="col-12 col-lg-8 col-xl-9 pe-lg-10">
          <el-input
            class="comment-box"
            v-model="comment"
            :placeholder="$t('Leave your comment here...' )"
          />
        </div>
        <div class="col-12 col-lg-4 col-xl-3">
          <button
            class="btn mt-5 mt-lg-0 main-btn py-5 w-100 mw-100"
            @click="addComment(comment)"
            :disabled="!comment || commentBtnLoading"
          >
            <span v-if="commentBtnLoading" class="spinner-border spinner-border-sm align-middle me-4"></span>
            {{ $t("Post Comment") }}
          </button>
        </div>
      </div>
    </div>

    <div class="card my-10 comment-card">
      <p class="text-gray">
        {{ $t("Comments") }}
        <span class="text-with-bg" v-if="idea.commentsContainer.comments.size">
          {{ idea.commentsContainer.comments.size }}
        </span>
      </p>
      <div class="bank-single-idea-request__comments">
        <div v-if="!idea.commentsContainer.comments.size" class="no-comments"> {{ $t('No comments yet') }} </div>
        <Comment
          v-else
          v-for="(item, index) in idea.commentsContainer.getComments()"
          :key="index"
          :item="item"
          :deletingItem="deletingItem"
          @handle-update="updateComment"
          @handle-delete="deleteComment"
          :is-bank-user="item.creator.is_bank_user"
        ></Comment>
      </div>
    </div>

  </div>
</template>
<script>
import store from "../../../../store";
import CustomLoader from "@/buying-teams/shared-components/utils/CustomLoader";
import { RequestStatusEnum } from "@/store/enums/request/RequestStatusEnum";
import { RequestStatusModel } from "@/store/models/request/RequestStatusModel";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {PlatformTypeEnum} from "@/store/enums/PlatformTypeEnum";
import BankIdeaItem from "@/buying-teams/pages/bank/ideas/BankIdeaItem";
import Comment from "@/buying-teams/shared-components/request-idea/Comment";
import { BankUserPermissionTypeEnum } from "@/store/enums/BankUserPermissionTypeEnum";

export default {
  name: "BankSingleIdea",

  components: {
    Comment,
    BankIdeaItem,
    CustomLoader
  },
  emits: [],

  data() {
    return {
      BankUserPermissionTypeEnum,
      PlatformTypeEnum,
      loading: true,
      idea: null,
      comment: "",
      commentBtnLoading: false,
      deletingItem: null
    };
  },

  computed: {
    userInfo() {
      return store.getters.currentUser
    },
    currentUser() {
      return store.getters.currentUser;
    }
  },

  async mounted() {
    setCurrentPageBreadcrumbs({
      title: this.$t('Ideas'),
      subTitle: this.$t('Ideas for your Customers (improvements, developments, other)')
    });
    await this.getBankRequestData(+this.$route.params.id);
  },

  methods: {
    async getBankRequestData(ideaId) {
      this.loading = true;
      await store.dispatch('getBankIdeaDetails', ideaId).then(res => {
        this.idea = res;
      }).finally(() => {
        this.loading = false;
      })
    },

    async addComment(comment) {
      if (!comment && this.commentBtnLoading) return;
      this.commentBtnLoading = true
      let data = {
        intent_id: this.$route.params.id,
        body: comment
      };
      store.dispatch("saveComment", data).then(res => {
        this.idea.commentsContainer.addComment(res.data);
        this.comment = '';
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.commentBtnLoading = false
      });
    },

    async updateComment(data) {
      this.idea.commentsContainer.updateComment(data);
    },

    async deleteComment(id) {
      // TODO check deletingItem variable
      store.dispatch("deleteComment", id).then(res => {
        this.idea.commentsContainer.deleteComment(id);
      }).catch(err => {
        console.log(err);
      });
    },

    async toggleIdeaStatus(ideaId) {
      let status = this.idea.status.code === RequestStatusEnum.OPEN ? RequestStatusEnum.CLOSED : RequestStatusEnum.OPEN;
      const params = {
        id: ideaId,
        status: status
      };
      await store.dispatch("toggleIdeaStatus", params)
        .then(res => {
          if (res) {
            this.idea.status = new RequestStatusModel(status);
          }
        });
    },

    goBack() {
      this.$router.go(-1);
    }
  }

};
</script>
<style lang="scss">
.comment-box {
  input {
    height: 50px;
    background: #F8F8F8;
    box-shadow: inset 0px 1.12463px 4.4985px rgba(0, 0, 0, 0.05);
    border-radius: 7px;
  }
}
.no-comments {
  margin-top: 10px;
  text-align: center;
  color: #959595;
}
.bank-single-idea-request {
  &__comments {
    margin-left: -32px;
    margin-right: -32px;
    .comment-card__item {
      padding-left: 32px;
      padding-right: 32px;
      margin-top: 0;
      padding-top: 26px;
      position: relative;
      &[is-bank-user="true"] {
        > div {
          position: relative;
          z-index: 2;
        }
        &:after {
          content: '';
          position: absolute;
          z-index: 1;
          left: 0;
          top: 0;
          bottom: 0;
          width: 150px;
          background: linear-gradient(270deg, #F4F6FF -55.82%, rgba(243, 245, 255, 0.470486) 32.15%, rgba(242, 244, 255, 0) 117.12%);
          border-radius: 0px;
          transform: matrix(-1, 0, 0, 1, 0, 0);
          border-right: 4px solid #435BF4;
        }
      }
    }
  }
}
</style>

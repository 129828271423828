<template>
  <div
    class="comment-card__item"
   :class="{'isEdit' : isEdit, 'deletingItem': deletingItem === item.id}" :key="key"
  >
    <div class="comment-card__item--img">
      <img class="rounded-circle" :src="item.creator.icon_path" alt="">
    </div>
    <div class="comment-card__item--content">
      <div class="d-flex justify-content-between">
        <div class="d-flex flex-wrap gap-3">
          <h3 class="mb-3">{{ item.creator.full_name }}</h3>
          <p class="border-end text-gray-500 pe-3">
            <img class="rounded-circle" :src="item.business.icon_path" width="15" height="15" alt="">
            {{ item.business.business_name }}
          </p>
          <p class="border-end text-gray-500 pe-3">{{ item.creator.job_title }}</p>
          <p class="text-gray-500">{{ moment(item.created_at).format('DD.MM.YYYY') }}</p>
        </div>

        <div class="comment-card__item--dropdown" v-if="item.permissions.can_delete || item.permissions.can_edit">
          <div class="me-0">
            <el-dropdown>
              <span class="el-dropdown-link">
                <i class="bi bi-three-dots fs-1 fw-bolder"></i>
              </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item
                    command="a"
                    @click="() => {
                      this.isEdit = true
                      this.comment = item.body
                    }"
                    v-if="item.permissions.can_edit"
                  >
                    {{$t("Edit Comment")}}
                  </el-dropdown-item>
                  <el-dropdown-item
                    command="b"
                    @click="dialogDeleteCommentVisible = true"
                    v-if="item.permissions.can_delete"
                  >
                    <span class="text-danger">{{$t("Delete Comment")}}</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
          <ConfirmationModal
            :title="$t('Delete Comment')"
            :description="$t('Confirm deleting this comment, this action cannot be undone')"
            :btnName="$t('Delete')"
            :popupVisible="dialogDeleteCommentVisible"
            @handleClose="dialogDeleteCommentVisible = false"
            @handleConfirm="deleteComment"
          />
        </div>
      </div>
      <p v-if="!isEdit">{{item.body}}</p>
      <div v-if="isEdit">
        <el-input
          class=""
          v-model="comment"
          type="textarea"
          :rows="3"
        />
        <div class="text-end actions d-flex justify-content-end pt-2">
          <button
            @click="() => {
              this.isEdit = false
              this.comment = ''
            }"
            class="btn text-gray-500"
          >{{$t("Cancel")}}</button>
          <button @click="editComment" :disabled="!comment || editLoading" class="open-close-btn open m-0">
            <span v-if="editLoading" class="spinner-border spinner-border-sm align-middle me-4"></span>
            {{$t("Update")}}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import moment from "moment";
  import store from "@/store";
  import ConfirmationModal from "@/buying-teams/shared-components/modals/ConfirmationModal";

  export default {
    name: "Comment",

    components: {
      ConfirmationModal
    },

    props: {
      item: {
        type: Object
      },
      key: Number,
      deletingItem: Number
    },

    data() {
      return {
        moment,
        isEdit: false,
        editLoading: false,
        comment: "",
        dialogDeleteCommentVisible: false
      };
    },

    methods: {
      deleteComment() {
        this.$emit('handle-delete', this.item.id)
      },
      editComment() {
        if (!this.comment && this.editLoading) return

        this.editLoading = true
        store.dispatch("updateComment", {id: this.item.id, body: this.comment}).then(res => {
          this.isEdit = false
          this.comment = ''
          this.$emit('handle-update', res.data)
        }).catch(err => {
          console.log(err);
        }).finally(() => {
          this.editLoading = false
        })
      }
    }
  };
</script>
<style lang="scss">
  .comment-card {
    overflow: hidden;
    &__item {
      margin-top: 26px;
      display: flex;
      &.deletingItem {
        opacity: .5;
        position: relative;
        &:after {
          content: '';
          position: absolute;
          z-index: 9999;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
        }
      }
      .isEdit {
        background: #F5FBFF;
      }
      .updateComment {
        position: relative;
        background: #FFFFFF;
        border: 1px solid rgba(0, 0, 0, 0.09);
        box-sizing: border-box;
        border-radius: 9px;

        textarea {
          border: none;
          border-radius: 10px;
          padding-bottom: 20px;
        }

        .actions {
          position: absolute;
          bottom: 0;
          right: 0;
        }
      }

      &--img {
        width: 61px;
        img {
          width: 51px;
          height: 51px;
          object-fit: cover;
        }
      }
      &--content {
        padding-left: 10px;
        padding-bottom: 13px;
        width: calc(100% - 61px);
        position: relative;
        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 150%;
          height: 1px;
          background: #F2F2F2;
        }
      }
    }
  }
</style>
